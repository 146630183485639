import React from 'react'
import { Link } from 'gatsby'
import "../../src/styles/form.css"
import Layout from '../components/layout'
import { Helmet } from "react-helmet"

const Form = () => (

  <Layout>
    <div className="form">
      <h2 className="common-heading" id="お問い合わせ">
        CONTACT
      </h2>

      <Helmet>
        <script src="https://challenges.cloudflare.com/turnstile/v0/api.js" async defer />
        <script src="https://sdk.form.run/js/v2/embed.js" />
      </Helmet>
      <div
        class="formrun-embed"
        data-formrun-form="@jinnouchi-1665983038"
        data-formrun-redirect="true">
      </div>


      <iframe src="https://form.run/embed/@jinnouchi-1665983038?embed=direct" width="600" height="400"></iframe>

      <form
        name="contact"
        method="POST"
        action='https://ssgform.com/s/WSPcY2lteUbI'
      // data-netlify="true"
      // data-netlify-honeypot="bot-field"
      >
        <div className='display-none'><input type="text" name="wana" /></div>
        <input type="hidden" name="form-name" value="contact" />
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="_recaptcha" id="_recaptcha" />


        <div class="Form">
          <div className="form__wrapper">

            <div class="Form-Item">
              <p class="Form-Item-Label"><span class="Form-Item-Label-Required">必須</span>氏名</p>
              <input required type="text" name='name' class="Form-Item-Input" placeholder="例）山田太郎" />
            </div>
            <div class="Form-Item">
              <p class="Form-Item-Label"><span class="Form-Item-Label-Required">必須</span>電話番号</p>
              <input required type="tel" name='tel' class="Form-Item-Input" placeholder="例）000-0000-0000" />
            </div>
            <div class="Form-Item">
              <p class="Form-Item-Label"><span class="Form-Item-Label-Required">必須</span>メールアドレス</p>
              <input required type="email" name='email' class="Form-Item-Input" placeholder="例）example@gmail.com" />
            </div>
            <div class="Form-Item">
              <p class="Form-Item-Label isMsg"><span class="Form-Item-Label-Required">必須</span>お問い合わせ内容</p>
              <textarea required name='message' class="Form-Item-Textarea"></textarea>
            </div>

            <input type="submit" class="Form-Btn" value="送信する" />
            {/* <button class="g-recaptcha Form-Btn"
              id='form'
              data-sitekey="6LfmbjgiAAAAAIr-aTXUKbzuyjz2TPBaRKrRy_ZN"
              data-callback="onSubmit"
            data-action="submit">送信する</button> */}
          </div>

        </div>
      </form>

      <div className="form-turnstile">
        <div class="cf-turnstile" data-sitekey="0x4AAAAAAAAsg-Dw_i5oATLy"></div>
      </div>


    </div>
  </Layout>

)


function onSubmit(token) {
  var form = document.getElementById('form');
  if (form.reportValidity()) {
    form.submit();
  }
}


export default Form;