// src/pages/404.js
import React from "react"
import Seo from "../components/seo"
import Form from "../components/form"

export default function Contact() {

  return (

    <div>
      <Seo title="お問合せフォーム" description="お問合せフォームです。" />
      <Form />
    </div>
  )
}
